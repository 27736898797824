import * as React from 'react'
import "../pages/index.css"
import Navigation from "../components/navbar"
import Homepage from "../pages/home"

const IndexPage = () => {
  return (
    <>
      <main>
        <title> Gabella - Home</title>
      </main>
      <Navigation/>
      <div className="container">
        <Homepage />
      </div>
    </>
  )
}

export default IndexPage
