import React from 'react'
import "../pages/home.css"
import flores1 from "/src/images/Flores2.png"
import flores2 from "/src/images/Flores1.png"
import logo from "/src/images/logo_gabella.png"



function Homepage() {
  return (
    <>
      <title>GABELLA - Home</title>
      <img src={flores2} className="home__flower2"/>
      <img src={flores1} className="home__flower1"/>
      <div className='home__container'>
        <div className='home__top'>
          <div className='home__logo'>
            <img src={logo} className="home__logo-img"/>
          </div>

          <div className='home__text'>
            <p className='t-m f-400'>#GABELLA <spa className='text__orange t-title'>07.01.23</spa> Panamá</p>
          </div>
        </div>

        <div className='home__info'>
          <div className='home__info-text'>
            <h3 className='text__rose lowercase m-0 t-m'>fecha</h3>
            <p className='t-sm f-400'>Sábado 7 de Enero 2023</p>
          </div>
          <div className='home__info-text'>
            <h3 className='text__rose lowercase m-0 t-m'>ceremonia</h3>
            <p className='t-sm mb-0 f-400'>Iglesia La Catedral</p>
            <p className='t-sm m-0 f-400'> Metropolitana de Panamá</p>
          </div>
          <div className='home__info-text'>
            <h3 className='text__rose lowercase m-0 t-m'>recepción</h3>
            <p className='mb-0 t-sm f-400'>PH Seapoint, Paitilla</p>
            <p className='m-0 t-sm f-400'>Ciudad de Panamá, Panamá </p>
          </div>
          <div className='home__info-text'>
            <h3 className='text__rose lowercase m-0 t-m'>dress code</h3>
            <a href="https://pin.it/4fKarQj" className='link-home'><p className='home__btn t-sm home__btn'>Formal</p></a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Homepage
